<template>
  <NuxtLink to="/">
    <MIcon :src="logoSrc" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { MM } from '~/types';

const props = defineProps<{
  withDescription?: boolean;
}>();

const logoSrc = computed(() => (props.withDescription ? MM.EIconPath.ImagesLogoDescOn : MM.EIconPath.ImagesLogoDescOff));
</script>
