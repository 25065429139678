<template>
  <header class="Header">
    <div class="Header__container">
      <div class="Header__left">
        <div
          v-if="props.links"
          class="Header__nav"
        >
          <HeaderNavigation :links="props.links" />
        </div>
      </div>
      <div class="Header__logo Header__logo--desktop">
        <HeaderLogo with-description />
      </div>
      <div class="Header__logo Header__logo--mobile">
        <HeaderLogo />
      </div>
      <div class="Header__right">
        <div class="Header__location">
          <!-- <ButtonLocationChange /> -->

          <MButton
            v-if="isUserLoggedIn"
            :type="MM.EButtonTypes.Text"
            :theme="MM.EButtonThemes.TextMain"
            :icon-left="MM.EIconPath.NavigationLocationSvg"
            :size="MM.EButtonSizes.Medium"
            @click="onOpenBasisSelectModal"
          >
            {{ addressToShow }}
          </MButton>
        </div>
        <div class="Header__user">
          <LoginButton />
        </div>
      </div>
    </div>
  </header>
  <!-- 
  <ClientOnly>
    <MobileMenu
      v-if="!hideMobileMenu"
      :oci-mode="ociMode"
    />
  </ClientOnly> -->
</template>

<script setup lang="ts">
import LoginButton from 'components/LoginButton.vue';
// import MobileMenu from 'components/MobileMenu.vue';
import { MM } from '~/types';

import HeaderLogo from './HeaderLogo.vue';
import HeaderNavigation from 'components/Header/HeaderNav/HeaderNav.vue';
import { useUserStore } from '~/store/user.store';

import { UserHelper } from 'utils/userHelper.util';
import { storeToRefs } from 'pinia';
import BasesService from '~/services/basesManager.service';
import { IModalManager } from 'shared/models/modalManager.model';
import ModalManager from 'shared/services/modalManager.service';

import { ILink } from 'models/link.model';
import { defaultHeaderLinks } from 'constants/layouts/header.const';

const props = withDefaults(
  defineProps<{
    dark?: boolean; // Темная тема
    hideSearch?: boolean; // Скрыть поиск
    hideMobileMenu?: boolean; // Скрыть мобильное меню
    hideCityChangeButton?: boolean; // Скрыть кнопку смены города
    changeColor?: boolean; // Должен ли изменяться цвет
    isCategoriesDisabled?: boolean; // Отключены ли категории
    isErp?: boolean; // Являетя ли Erp
    isErrorPage?: boolean; // Находится ли на странице ошибки
    isTransparent?: boolean; // Должен ли быть прозрачным
    links?: Array<ILink>; // Список ссылок
    hideMain?: boolean; // Скрыть основной контент
    searchPlaceholder?: string; // Плейсхолдер для поискового инпута
    showLocationInSearch?: boolean; // Отображается ли адресс в строке поиска
    locationInSearch?: string; // Адресс, который отображается в строке поиска
    disableLoadCookie?: boolean; // Отключить куки
    ociMode?: boolean; // Режим OCI
    clientId?: number; // id клиента
  }>(),
  {
    dark: false,
    hideSearch: false,
    links: defaultHeaderLinks,
  },
);

const modalManager = process.client ? inject<IModalManager>(ModalManager.getServiceName()) : undefined;

const userStore = useUserStore();
const { isUserLoggedIn } = storeToRefs(userStore);

const basesManager = inject<BasesService>(BasesService.getServiceName());
const addressToShow = computed(() => {
  if (!isUserLoggedIn.value || UserHelper.isSupplier) {
    return basesManager?.city ? basesManager?.city?.replaceAll?.('"', '') : 'Укажите ваш город';
  }
  return basesManager?.base ? basesManager.base?.replaceAll?.('"', '') : 'Базис доставки';
});

function onOpenBasisSelectModal(): void {
  basesManager?.openSelectModal(modalManager, isUserLoggedIn.value);
}
</script>

<style lang="scss" scoped>
.Header {
  position: sticky;
  z-index: 99;
  top: 0;
  padding: 16px 0;
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-light;

  &__container {
    width: calc(var(--container-width) - var(--container-margin-X) * 2);
    max-width: calc(100% - var(--container-margin-X) * 2);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  &__left {
    flex: 1 1 50%;
  }

  &__logo {
    flex: 0 0 auto;
    margin: 0 24px;
    height: 56px;
    &--mobile {
      display: none;
    }
  }

  &__right {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__nav {
  }

  &__user {
  }

  @include maxw(1279) {
    &__left {
      display: none;
    }
    &__logo {
      height: 28px;
      margin-left: 0;

      &--desktop {
        display: none;
      }
      &--mobile {
        display: block;
      }
    }
    &__nav {
      display: none;
    }
  }
}
</style>
